@import "styles/globals.scss";

.root {
    padding: 20px 0 10px 0;
}

.title {
    // margin-left: 15px;
    font-weight: 800;
    font-size: 17px;
    color: $font-color;
}

.healthList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.listItem {
    display: flex;
    gap: 20px;
    align-items: center;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    background-color: $soft;
    flex-shrink: 0;
    $size: 30px;
    width: $size;
    height: $size;

    .icon {
        color: $primary;
    }

    &.red {
        background-color: rgba(255, 0, 21, 0.1);

        .icon {
            color: rgb(134, 6, 17);
        }
    }
}

.itemText {
    font-size: 15px;
    color: $font-color-2;
    strong {
        color: $font-color;
    }
}
