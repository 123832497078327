@import "styles/globals.scss";

.root {
    position: relative;
    background-color: $background-2;
    border-radius: $radius;
    height: 100%;
    padding: 15px 15px;
    overflow: hidden;
    cursor: pointer;
    max-width: 100%;

    &.green {
        background-color: $soft;
    }
}

.dayNumber {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    font-weight: 800;
    color: $font-color-3;
    user-select: none;

    &.green {
        color: $primary;
    }
}

.eventList {
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    position: relative;
    z-index: 2;
    justify-content: flex-end;
    height: 100%;
}

.dot {
    $size: 10px;
    width: $size;
    height: $size;
    border-radius: 99px;
    margin-right: 6px;
    flex-shrink: 0;
}

.eventItem {
    display: flex;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 13px;
    align-items: center;

    &:hover {
        background-color: $transparent;
    }
}

.eventName {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 1;
    overflow: hidden;
}

.amount {
    padding: 2px 7px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 800;
    margin-left: 5px;
}

@media screen and (max-width: 700px) {
    .eventName,
    .amount {
        display: none;
    }

    .eventItem {
        padding: 4px 0px;
    }

    .root {
        padding: 15px 10px;
    }
}
