@import "styles/globals.scss";

.root {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px);
    background-color: $background;
    color: $font-color;
}

.header {
    padding: 20px 40px;
    display: flex;
    align-items: flex-start;
    user-select: none;
}

.headerInfo {
    flex-grow: 1;
}

.focusToday {
    padding: 5px 20px;
    border-radius: 5px;
    background-color: $background-2;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.title {
    font-size: 20px;
    font-weight: 800;
    color: $primary;
    padding: 0;
    line-height: 1;
}

.monthYear {
    padding: 10px 0 0 0;
    font-size: 30px;
    font-weight: 800;
    line-height: 1;
}

.calendarContainer {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
}

.minusMonth,
.plusMonth {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 10px 10px;
    font-size: 20px;
    color: $font-color-2;
    cursor: pointer;

    &:hover {
        color: $font-color;
    }
}

$gap: 5px;

.calendar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
}

.days {
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: $gap;
    user-select: none;

    > div {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }
}

.listEvents {
    flex-grow: 1;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: $gap;
}

.dayLine {
    display: flex;
    gap: $gap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
}

.dayElm {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    overflow: hidden;
}

.dayValid {
    height: 100%;
}
