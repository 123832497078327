@import "styles/globals.scss";

$footer-height: 200px;

.rootDesktop {
    display: flex;
    background-color: $white;

    > .login {
        min-height: 100vh;
        width: 500px;
        position: relative;

        > .container {
            padding: 0 30px $footer-height 30px;
        }
    }

    > .background {
        flex-grow: 1;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;

        > .coverBackground {
            background-color: #165768;
            min-height: 100vh;
            opacity: 1;
            transition: opacity 0.3s;
        }
    }
}

.loginMobile {
    background-color: $white;
    position: relative;
    min-height: 100vh;
    padding: 0 0 $footer-height 0;
}

$padding: 20px;

.rootBase {
    padding: 0 0 30px 0;
    .logo {
        padding: 60px $padding 40px $padding;
        > img {
            height: 25px;
        }
    }

    form {
        padding: 0 $padding;
        > .labelConexion {
            text-transform: uppercase;
            font-weight: 800;
            margin: 20px 0 40px 0;
            color: $font-color;
            font-size: 0.9rem;
        }

        > .input {
            margin-top: 30px;
        }

        > .button {
            margin-top: 40px;
        }
    }

    .line {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: 40px $padding 40px $padding;
    }

    .loginProviders {
        padding: 0 $padding;
        .provider {
            //width: 100%;
            color: white;
            font-weight: 800;
        }

        .providerGoogle {
            @extend .provider;
            background-color: #4385f4;

            &:hover {
                background-color: #2c66cc;
            }
        }

        .providerFacebook {
            @extend .provider;
            background-color: #4266b2;

            &:hover {
                background-color: #224692;
            }
        }
    }

    .footer {
        position: absolute;
        padding: 40px $padding;
        background-color: $background;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $footer-height;

        @media (min-width: 500px) {
            padding: 40px ($padding + 30px);
        }

        .footerText {
            color: $primary;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 0.8rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
