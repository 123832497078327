@import "./styles/globals.scss";

$body-size: 2px;

html {
    //font-size: 0.9rem !important;
    --bg-color: #f6f7fa;
    --soft-color: #d8f2e6;
    --white-color: white;
    --font-color: black;
    min-height: 100vh;
    background-color: var(--white-color) !important;
    transition: background-color 0.3s;
}

body {
    background-color: $background !important;
    min-height: 100vh;
    margin: 0;
    font-family: Roboto, Poppins !important;
    height: initial !important;
}

.ant-picker-panel-container {
    background-color: $white !important;

    .ant-picker-header {
        border-bottom: 1px solid $transparent-hover !important;

        button {
            color: $font-color-3 !important;
        }

        .ant-picker-header-view button,
        .ant-picker-header-view {
            color: $font-color !important;
        }
    }

    .ant-picker-cell {
        color: $transparent-hover;
    }

    .ant-picker-footer {
        border-top: 1px solid $transparent-hover !important;
        border-bottom: 1px solid $transparent-hover !important;
    }

    .ant-picker-panel-focused {
        border-bottom: 1px solid $transparent-hover !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: $primary !important;
    }

    .ant-picker-cell-in-view {
        color: $font-color-2;
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background-color: $background-2;
    }

    .ant-picker-content th {
        color: $font-color;
    }

    .ant-picker-today-btn {
        color: $primary;
    }

    .ant-picker-today-btn:hover {
        color: $secondary;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid $primary;
    }
}
