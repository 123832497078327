@import "styles/globals.scss";

.root {
    padding: 20px 0 10px 0;
    display: flex;
    flex-direction: column;
}

.title {
    // margin-left: 15px;
    font-weight: 800;
    font-size: 17px;
    color: $font-color;
    flex-grow: 1;
}

.header {
    display: flex;
    gap: 30px;
    align-items: center;
}

.inputSettings {
    font-size: 13px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 300px;
    margin-top: 10px;
}

.category {
    font-size: 12px;

    background-color: $background;
    color: $font-color-3;
    border-radius: 999px;
    font-weight: 800;
    padding: 2px 8px;
    cursor: pointer;

    &:hover {
        color: $font-color-2;
        background-color: $background-2;
    }

    &.active {
        background-color: $soft;
        color: $primary;
    }
}

.future {
    margin-top: 20px;
    background-color: $background;
    border-radius: $radius;
    padding: 10px;
}

.futureHeader {
    font-size: 13px;
    color: $primary;
    font-weight: 800;
}

.futureData {
    font-size: 40px;
    font-weight: 800;
    line-height: 1;
}

.chart {
    color: $font-color;
}
