@import "styles/globals.scss";

.dataChart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    width: 30vw;
    max-width: 450px;
}

.dataChartCategory {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    width: 25vw;
    max-width: 500px;
    flex-grow: 2;
}

.layoutGlobal {
    width: 100%;
    display: flex;
    padding: 40px 40px;
    gap: 40px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.sectionLayout {
    color: $font-color-2;
    background-color: $background;
    min-height: calc(100vh - 30px);
    display: flex;
    flex-direction: column;
}

.sectionGlobal {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40vw;
    height: 30vh;
    min-width: 400px;
    min-height: 230px;
    max-height: 500px;
    background-color: $white;
    border-radius: 50px;
    margin-top: 5px;
}

.sectionCategory {
    display: flex;
    justify-content: space-evenly;
    width: 85vw;
    min-width: 600px;
    min-height: 230px;
    height: 40vh;
    background-color: $white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-top: 5px;
}

.diagramInfos {
    max-height: 90%;
    flex-direction: column;
    vertical-align: middle;
    justify-content: space-between;
}

.arrowIcon {
    vertical-align: middle;
    color: $font-color-2;
    flex-grow: 1;
}

.healthIcons {
    vertical-align: middle;
    color: $primary;
}

.listItem {
    display: flex;
    justify-content: center;
    width: 15vw;
    min-width: 250px;
    background-color: $background;
}

.scrollableList {
    height: 80%;
    min-width: 250px;
    overflow-y: scroll;
    display: flex;
    vertical-align: middle;
    flex-grow: 0;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $background;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.listButton {
    border-radius: 0px;
    width: 100%;
}

.titleSection {
    font-size: 25px;
    font-weight: bold;
    color: $font-color;
    margin-left: 40px;
}

.sectionHealth {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
}

.healthStats {
    font-size: 30px;
    color: $font-color;
    margin: 0px 5px 0px 15px;
}

.infosHealth {
    display: inline-block;
    text-align: left;
}

.categoryTitle {
    font-size: 20px;
    font-weight: bold;
    color: $font-color-2;
}

.scrollInfos {
    text-align: center;
}

.futurExpenseCategory {
    max-width: 20%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    span {
        margin-top: 30px;
        font-size: 40px;
        font-weight: bold;
    }
}

.widget {
    @extend .shadow;
    background-color: $white;
    border-radius: 50px;
    padding: 20px;
}
