@import "styles/globals.scss";

.root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    gap: 20px;
}

// .category {
// }

.inputTitle {
    @extend .shadow-05;
    background-color: $white;
    border: $border;
    padding: 25px 0;
}

// .firstLine {
// }

.title {
    font-weight: 800;
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.dateChoice {
    @extend .shadow-05;
    height: 100%;
    min-height: 35px;
    display: flex;
    border-radius: $radius;
    border: $border;
    overflow: hidden;
    background-color: $white;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    width: 310px;

    .now {
        color: $font-color;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 20px;
        flex-grow: 1;

        &.activeDate {
            background-color: $primary;
            color: white;
            font-weight: 800;
        }
    }

    .datePicker {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 20px;
        flex-grow: 1;

        &.activeDate {
            background-color: $primary;
            color: white;
            font-weight: 800;
        }
    }
}

.amountSetter {
    @extend .shadow-05;
    border: $border;
    border-radius: $radius;
    overflow: hidden;
    display: flex;
    height: 50px;
    align-items: stretch;
    background-color: $white;

    .itemChoice {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: $border;
        width: 40px;
        flex-shrink: 0;
        cursor: pointer;

        &:hover {
            background-color: $transparent;
        }

        &.active {
            background-color: $primary;
            color: white;
        }
    }

    .inputAmount {
        background-color: $white;
        color: $font-color;
        border: none;
        outline: none;
        width: 100%;
        flex-grow: 1;
        padding: 0 10px;
    }
}

.submit {
    align-self: flex-end;
}

.dropdownCategory button {
    @extend .shadow-05;
    background-color: $white !important;
}

.advance {
    background-color: $transparent;
    overflow: hidden;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
}

.advanceTrigger {
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        background-color: $transparent-hover;
    }
}

.advanceTriggerContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 15px;
    color: $font-color-2;

    > svg {
        transition: transform 0.3s;
    }
}

.advanceRoot {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    gap: 40px;
}

.reccuringType {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: $font-color;
    gap: 20px;
    font-weight: 800;
}

.reccuringTypeChoice {
    display: flex;
    color: $font-color-2;
    gap: 10px;
    overflow: hidden;
    overflow-x: auto;
    flex-shrink: 1;
    border-radius: $radius;
    background-color: $background;

    > div {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 40px;
        padding: 10px 10px;
        min-width: 70px;
        cursor: pointer;

        &.active {
            color: $font-color;
            background-color: $transparent-hover;
        }
    }
}
