@import "styles/globals.scss";

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    > div {
        color: $font-color-2;
    }
}
