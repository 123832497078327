@import "styles/globals.scss";

.root {
    position: relative;
    background-color: $transparent;
    border-radius: $radius;
    border: $border;
    height: 50px;
    display: flex;
    align-items: center;

    &.small {
        height: 35px;
        font-size: 0.8rem;
    }

    > input {
        outline: none;
        width: 100%;
        height: 100%;
        border: none;
        padding: 8px 18px;
        font-size: 1rem;
        background-color: transparent;
        color: $font-color-2;
    }

    .label {
        position: absolute;
        font-size: 1rem;
        top: 50%;
        transform: translateY(-50%);
        left: 18px;
        color: $font-color-2;
        transition: all 0.2s;
        pointer-events: none;
    }

    .activeLabel {
        top: -10px;
        font-size: 0.8rem;
        color: $font-color;
    }
}
