@import "styles/globals.scss";

.root {
    @extend .reset-button;
    border-radius: $radius;
    padding: 3px 18px;
    color: $font-color-2;
    border: $border;
    cursor: pointer;

    &.small {
        font-size: 0.8rem;
        padding: 2px 14px;
        line-height: 1;
    }
}

.root-default {
    @extend .root;

    background-color: $transparent;

    &:hover {
        background-color: $transparent-hover;
    }
}

.root-primary {
    @extend .root;

    background-color: $primary;
    color: white;

    &:hover {
        background-color: $secondary;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text {
    margin: 4px 0;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $transparent-hover;
    text-align: center;
    border-radius: 999px;
    margin-left: 20px;
    padding: 3px;
}
