@import "styles/globals.scss";

.title {
    @extend .shadow-2;
    border-bottom: 1px solid $border;
    background-color: $white;
    font-size: 1.1rem;
    font-weight: 800;
    text-align: center;
    padding: 20px 15px;
    color: $font-color;
    position: relative;
    z-index: 10;
}

.wrapper {
    background-color: $background;
}

.content {
    margin: 0 auto;
    padding: 30px 20px;
    max-width: 800px;
}
