@import "styles/globals.scss";

.root {
    padding: 20px 0 0 0;
}

.title {
    margin-left: 15px;
    font-weight: 800;
    font-size: 17px;
    color: $font-color;
}
