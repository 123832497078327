@import "styles/globals.scss";

.root {
    position: relative;
}

.square {
    @extend .shadow-05;
    overflow: hidden;
    height: 50px;
    width: 50px;
    background-color: $white;
    border-radius: $radius;
    border: $border;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .iconPick {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $font-color-3;
        font-size: 10px;
        opacity: 0;
        transition: all 0.3s;
    }

    &:hover {
        .iconPick {
            background-color: $transparent;
            font-size: 17px;
            opacity: 1;
        }
    }
}

.picker {
    @extend .shadow;
    $border: $border;
    position: absolute;
    top: 55px;
    background-color: $white;
    border-radius: $radius;
    padding: 5px;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 105px;
    z-index: 5;
}

.option {
    width: 20px;
    height: 20px;
    border-radius: $radius;
    overflow: hidden;
    cursor: pointer;

    .hoverEvent {
        opacity: 0;
        background-color: rgb(255, 255, 255);
        width: 20px;
        height: 20px;
    }

    &:hover {
        .hoverEvent {
            opacity: 0.3;
        }
    }
}
