@import "styles/globals.scss";

.root {
    position: fixed;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 30;
    visibility: hidden;
    transition: opacity 0.3s;
    opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    overflow-y: scroll;
    padding: 50px 20px;

    &.begin {
        visibility: initial;
    }

    &.active {
        visibility: initial;
        opacity: 1;
    }
}

.modal {
    border-radius: $radius;
    flex-grow: 1;
    background-color: $white;
    max-width: 900px;
    overflow: hidden;
    cursor: default;
}
