$primary: #00a47e;
$secondary: #0a8266;
$background: var(--bg-color, #f6f7fa);
$background-2: var(--bg-color-2, #ebeef7);
$soft: var(--soft-color, #d8f2e6);
$accent: #fada58;
$transparent: var(--transparent-color, rgba(0, 0, 0, 0.03));
$transparent-hover: var(--transparent-hover-color, rgba(0, 0, 0, 0.07));
$white: var(--white-color, white);
$font-color: var(--font-color, black);
$font-color-2: var(--font-color-2, rgba(0, 0, 0, 0.7));
$font-color-3: var(--font-color-3, rgba(0, 0, 0, 0.5));

$radius: 5px;
$border: 1px solid var(--border-color, rgba(0, 0, 0, 0.03));

.reset-button {
    display: block;

    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    outline: none;
    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

.shadow-05 {
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}

.shadow {
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
}

.shadow-2 {
    -webkit-box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.04);
}
