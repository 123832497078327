@import "styles/globals.scss";

$nav-height: 50px;

.rootDesktop {
    margin-bottom: $nav-height;

    > nav {
        -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.04);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        position: fixed;
        top: 0;
        left: 0;
        height: $nav-height;
        width: 100%;
        background-color: $white;
        color: $font-color;
        z-index: 20;
    }

    .leftMenu {
        display: flex;
        align-items: center;

        > div {
            margin-right: 30px;
        }
    }

    .planifyLogo {
        display: flex;
        align-items: center;
        > img {
            height: $nav-height - 20px;
            width: $nav-height - 20px;
        }
    }

    .planifyTitle {
        font-size: 1.2rem;
        font-weight: 800;
    }

    .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        color: $font-color-2;

        &:hover {
            color: $font-color;
        }

        &.active {
            color: $primary;
        }

        > .itemElm {
            position: relative;
            height: $nav-height;
            display: flex;
            align-items: center;
            justify-content: center;

            > .underline {
                $height: 3px;
                height: $height;
                width: 50%;
                background-color: $primary;
                position: absolute;
                bottom: -$height;
                border-radius: 99px 99px 0 0;
                transition: bottom 0.3s, width 0.3s;

                &.active {
                    bottom: 0;
                    width: 100%;
                }
            }
        }

        > .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            padding: 5px;
            border-radius: 999px;
            height: $nav-height - 20px;
            width: $nav-height - 20px;
            background-color: transparent;
            transition: background-color 0.3s;

            &.active {
                background-color: $soft;
            }
        }
    }

    .rightMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $primary;
        height: $nav-height - 20px;
        width: $nav-height - 20px;
        border-radius: 999px;
        background-color: $soft;
        color: $primary;
        cursor: pointer;
    }
}

.rootMobile {
    $heightMenu: 60px;
    height: $heightMenu;

    .backMenu {
        @extend .shadow-2;
        position: fixed;
        background-color: $white;
        width: 100%;
        top: 0;
        transition: height 0.3s;
        z-index: 20;
        height: $heightMenu;
        overflow: hidden;
    }

    .topMenu {
        display: flex;
        align-items: center;
        height: $heightMenu;

        .logo {
            margin: 0 20px;
            font-size: 20px;
            line-height: 0;
            color: $font-color;
        }

        .appName {
            color: $primary;
            font-weight: 800;
            font-size: 1.1rem;
        }
    }

    .menuContain {
        opacity: 1;
        transition: opacity 0.3s;
    }

    .user {
        background-color: $primary;
        color: white;
        padding: 20px 20px;
    }

    .userName {
        font-weight: 800;
        font-size: 1.2rem;
    }

    .deconectButton {
        background-color: rgba(0, 0, 0, 0.2);
        display: inline;
        border-radius: 3px;
        padding: 3px 10px;
        font-size: 0.7rem;
    }

    .items {
        padding: 40px 0px;
    }

    .itemElmList {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        color: $font-color;

        &:hover {
            background-color: $transparent-hover;
        }

        &.active {
            color: $primary;
        }
    }

    .iconElm {
        display: block;
        line-height: 0;
        font-size: 25px;
        margin-right: 40px;
    }

    .elmItem {
        font-size: 1.1rem;
    }

    .settings {
        position: absolute;
        bottom: 40px;
        right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $primary;
        height: 50px;
        width: 50px;
        border-radius: 999px;
        background-color: $soft;
        color: $primary;
        line-height: 0;
        font-size: 20px;
        cursor: pointer;
    }
}
