@import "styles/globals.scss";

.antPicker {
    visibility: hidden;
    padding: 0;
    border: none;
    > div {
        display: none;
    }
}

.spinner {
    margin-left: 20px;
    > div {
        width: 15px !important;
        height: 15px !important;
    }
}
