@import "styles/globals.scss";

.root {
    position: relative;

    > .button {
        @extend .reset-button;
        height: 40px;
        display: flex;
        background-color: $transparent;
        border-radius: $radius;
        align-items: center;
        justify-content: space-between;
        padding: 5px 18px;
        cursor: pointer;
        border: $border;
        width: inherit;
        color: $font-color-2;

        > .text {
            overflow: hidden;
            text-align: left;
        }

        &:hover {
            background-color: $transparent-hover;
        }

        > .icon {
            transform: rotate(0deg);
            transition: all 0.3s;
            margin-left: 10px;
        }

        > .icon.active {
            transform: rotate(180deg);
        }
    }

    > .drop {
        @extend .shadow;
        border: $border;
        position: absolute;
        background-color: $white;
        top: 50px;
        border-radius: 5px;
        padding: 8px 0;
        min-width: 200px;
        overflow-y: scroll;
        color: $font-color-2;

        > .option {
            padding: 2px 18px;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                background-color: $transparent;
            }
        }
    }
}
