@import "styles/globals.scss";

:export {
    primary: $primary;
    fontColor: $font-color;
    fontColor2: $font-color-2;
}

$nav-height: 30px;

.sideNavSettings {
    width: 200px;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
}

.navItemDesktopSettings {
    width: 50%;
    height: 100vh;
    padding: 30px 30px;
    margin: 0px 50px;
    flex: 1;
    min-width: 500px;
}

.navItemMobileSettings {
    width: 50%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 30px 30px;
    min-width: 90%;
}

.settingsDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: $background;
}

.settingsMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $background;
}

.itemMobile {
    cursor: pointer;
    overflow: hidden;
    align-items: center;
    color: $font-color-2;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;

    > .itemElmMobile {
        height: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: transparent;
        transition: background-color 0.3s;

        > .link {
            color: $font-color;
        }

        &.active {
            background-color: $soft;
            font-weight: 800;

            > .link {
                color: $primary;
            }
        }
    }
}

.itemDesktop {
    padding: 10px 0px;
    border-radius: $radius;
    width: 100%;
    overflow: hidden;
    align-items: center;
    color: $font-color-2;

    > .itemElmDesktop {
        margin: 0px 20px;
        border-radius: 8px;
        position: relative;
        height: $nav-height;
        flex-grow: 1;
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: left;
    }

    transition: background-color 0.3s;

    &:hover {
        background-color: $transparent;
        color: $font-color-2;
    }

    &.active {
        background-color: $soft;
        color: $primary;
        font-weight: 800;
    }
}

.inputSettings {
    margin: 20px 0px;
}

.sectionInfo {
    padding: 0px 0px 30px 0px;
}

.rootMobile {
    color: $font-color;
    display: flex;
    min-width: 100vw;
    flex-direction: column;

    .topBar {
        width: 100vw;
        $height: 40px;
        @extend .shadow;
        background-color: $white;
        height: $height;
        min-height: $height;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
}

.rootDesktop {
    color: $font-color;
    display: flex;
    min-height: 100vh;

    > div {
        min-height: 100%;
    }

    .leftBar {
        $width: 300px;
        padding: 30px 20px;
        @extend .shadow;
        background-color: $white;
        width: $width;
        min-width: $width;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .content {
        background-color: $background;
        padding: 10px 10px;
    }
}

.switchComponent {
    color: $primary;
}

.label {
    margin: 30px 0px 5px Opx;
    color: $font-color;
    text-align: left;
    font-weight: bold;
}

.logOut {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.csv {
    display: flex;
    align-items: center;
}

.inputCSV {
    display: none;
}

.inputCSVButton {
    font-size: 20px;
    color: $font-color;
    margin-right: 20px;
    background-color: $transparent;
    $size: 40px;
    height: $size;
    width: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    outline: 0;
    cursor: pointer;
    border: $border;
}

.rootBanks {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.itemBridge {
    margin-bottom: 20px;
}
