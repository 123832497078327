@import "styles/globals.scss";

.root {
    color: $font-color;
}

.left {
    border-right: $border;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    min-height: 100%;
}

.titleLeft {
    font-weight: 800;
    font-size: 1.3rem;
}

.listEvents {
    flex-grow: 1;
}

.right {
    background-color: $background;
    min-height: 500px;
    padding: 15px 15px;
    display: flex;
    align-self: stretch;
    height: 100%;
}

.listEvents {
    margin: 20px 0;
}

.headerList {
    @extend .shadow-05;
    color: $primary;
    background: $soft;
    padding: 5px 15px;
    font-size: 0.9rem;
    font-weight: 800;
    border-radius: $radius;
    border: 1px solid $soft;
}

.itemsList {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    font-size: 0.8rem;

    .itemList {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        border-radius: $radius;
        padding: 3px 15px;

        &:hover {
            background-color: $background;
        }

        .roundItem {
            background-color: $primary;
            border-radius: 999px;
            height: 7px;
            width: 7px;
            margin: 0 10px 0 0;
            flex-shrink: 0;
        }

        .titleItem {
            flex-grow: 1;
        }

        .earnItem {
            margin: 0 0 0 10px;
            padding: 3px 5px;
            line-height: 1;
            font-weight: 800;
            border-radius: $radius;
            font-size: 0.7rem;
        }

        .deleteEvent {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 7px;
            font-size: 13px;
            color: $font-color-2;
            padding: 2px;
            border-radius: 99px;
            background-color: $transparent;
            cursor: pointer;

            &:hover {
                color: $font-color;
                background-color: $transparent-hover;
            }
        }
    }
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    color: $font-color-2;

    > div {
        display: flex;
        align-items: center;
        border-radius: $radius;
        padding: 5px 5px;
        cursor: pointer;

        &:hover {
            color: $font-color;
            background-color: $background;
        }

        > div:last-child {
            margin-left: 15px;
        }

        > div:first-child {
            margin-right: 15px;
        }
    }
}

.loading {
    @extend .shadow-05;
    background-color: $background;
    padding: 5px 0;
    border-radius: $radius;
    margin-bottom: 10px;
    border: $border;
}
