@import "styles/globals.scss";

$side: 20px;

.root {
    background-color: $background;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    padding: 30px $side;
    gap: 20px;
}

.headerLogo {
    height: 20px;
}

.headerLink {
    font-size: 15px;
    font-weight: 800;
    color: $font-color-2;
}

.panel {
    @extend .shadow;
    margin: 0 $side;
    background-color: $white;
    border-radius: 20px;
    display: flex;
    align-items: stretch;
    min-height: 300px;
    overflow: hidden;
}

.leftPanel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    padding: 50px $side 30px $side;
}

.panelTitle {
    color: $primary;
    font-weight: 800;
    font-size: 30px;
    line-height: 1;
}

.panelDescription {
    margin-top: 10px;
    font-size: 15px;
    color: $font-color-2;
}

.buttonPanel {
    display: inline-flex;
    gap: 20px;
    border-radius: $radius;
    background-color: $primary;
    color: white;
    align-items: center;
    padding: 5px 15px;
    font-weight: 800;
    font-size: 15px;
}

.rightPanel {
    flex-grow: 1;
    flex-basis: 0;
    background-repeat: no-repeat;
    background-size: 90% auto;
    background-position: bottom -10px right -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;

    & {
        @media screen and (max-width: 700px) {
            display: none;
        }
    }

    > img {
        @extend .shadow;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: $radius;
        max-height: 300px;
    }
}

.subSection {
    margin: 60px 0 0 0;
    background-color: $white;
    flex-grow: 1;

    > div {
        padding: 60px $side;
    }
}
